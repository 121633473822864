const PRODUCTION_TRACKER_ID = 'G-FJ0GPB9TP8';
const STAGING_TRACKER_ID = 'G-KL1V57GH0Z';

const host = window.location.hostname;
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

if (host.includes('staging.digitalpasifik.org')) {
  gtag('config', STAGING_TRACKER_ID);
} else {
  gtag('config', PRODUCTION_TRACKER_ID);
}